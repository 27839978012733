<template>
  <component :is="responseComponent" :request="request" @close="$emit('close')" />
</template>

<script>
import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';

import {
  BalanceAlignment,
  BalanceAlignmentReconciliationStatement,
  BalanceAlignmentMissingDocuments,
} from './responseModals';
import { REQUEST_TYPES } from '../../constants';

const responseComponents = {
  [REQUEST_TYPES.BALANCE_ALIGNMENT]: BalanceAlignment,
  [REQUEST_TYPES.BALANCE_ALIGNMENT_RECONCILIATION_STATEMENT]: BalanceAlignmentReconciliationStatement,
  [REQUEST_TYPES.BALANCE_ALIGNMENT_MISSING_DOCUMENTS]: BalanceAlignmentMissingDocuments,
};

export default {
  components: { Button, CloseIcon },
  props: {
    request: { type: Object, required: true },
  },
  setup(props) {
    return {
      responseComponent: responseComponents[props.request.template],
    };
  },
};
</script>
