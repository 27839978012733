<template>
  <div>
    <PageLayout class="container">
      <template #header>
        <h1>{{ $t('requests.title') }}</h1>
      </template>
      <template #tabs>
        <Tabs :tabs="tabs" :active-tab.sync="activeTab" @click="handleTabClick" />
      </template>
      <router-view />
    </PageLayout>
  </div>
</template>

<script type="text/javascript">
import { ref, watch, getCurrentInstance, computed } from 'vue';

import { PageLayout, Tabs } from '@/modules/core';
import { useTenancy } from '@/modules/auth';

import { useRequests, useCreateResponse } from './compositions';

export default {
  components: { PageLayout, Tabs },
  setup() {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const activeTab = ref(null);

    const requestVariables = {
      limit: 1,
      offset: 0,
      targetBusinessId: currentTenant.value.id,
      closed: false,
      templates: ['balanceAlignment', 'balanceAlignmentReconciliationStatement', 'balanceAlignmentMissingDocuments'],
    };

    const { totalCount: openRequestsCount, refetch: openRequestsRefetch } = useRequests(
      computed(() => ({ ...requestVariables, isAwaitingResponse: true }))
    );
    const { totalCount: inReviewRequestsCount, refetch: inReviewRequestsRefetch } = useRequests(
      computed(() => ({ ...requestVariables, isAwaitingResponse: false }))
    );

    const { onDone: onResponseCreated } = useCreateResponse();
    onResponseCreated(() => {
      openRequestsRefetch();
      inReviewRequestsRefetch();
    });

    const tabs = computed(() => [
      {
        text: root.$t('requests.tabs.open'),
        path: 'open',
        name: 'openRequestsSupplier',
        badgeValue: openRequestsCount.value,
      },
      {
        text: root.$t('requests.tabs.inReview'),
        path: 'in-review',
        name: 'inReviewRequestsSupplier',
        badgeValue: inReviewRequestsCount.value,
      },
      {
        text: root.$t('requests.tabs.closed'),
        path: 'closed',
        name: 'closedRequestsSupplier',
      },
    ]);

    const handleTabClick = (tabIndex) => {
      const selectedTab = tabs.value[tabIndex];
      root.$router.push({
        path: selectedTab.path,
        name: selectedTab.name,
      });
    };

    watch(
      () => root.$route.name,
      (name) => {
        activeTab.value = tabs.value.findIndex((t) => t.name === name);
      },
      { immediate: true }
    );

    return {
      tabs,
      activeTab,
      handleTabClick,
    };
  },
};
</script>
<style scoped>
.container {
  min-width: 1480px;
}
</style>
